<template>
  <div class="download-page">
    <common-header></common-header>
    <div class="download-content">
      <div class="download-header"></div>
      <div class="download-body">
        <img
          class="download-title-img"
          :src="require('@/assets/en/download/download.png')"
        />
        <div class="download-table">
          <el-table
            :data="tableData"
            style="width: 100%;"
            :header-cell-style="{
              'text-align': 'center',
              'border-bottom-color': '#eac596',
              'font-size': '20px',
              'color': '#000',
              'font-family': 'PingFang SC'
            }"
            :row-style="{
                'height': '90px',
                'line-height': '90px'
            }"
            :cell-style='changeCellStyle'
          >
            <el-table-column prop="products" label="Product information"></el-table-column>
            <el-table-column prop="type" label="subcategory" align="center"></el-table-column>
            <el-table-column prop="fileSize" label="File size" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="Update time" align="center"></el-table-column>
            <el-table-column label="Download" align="center">
              <template slot-scope="scope">
                <img :src="$http + '/zh/download/downloadIcon.png'" @click="downloadFunc(scope.row)" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="Download confirmation"
      :visible.sync="downloadDialog"
      width="35%"
      :before-close="handleClose"
      :close-on-press-escape='false'
      :close-on-click-modal='false'>
      <el-form :model="downloadForm" ref="downloadForm" :rules="downloadRules" label-width="30%">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Serial number License：" prop="serialNumber">
              <el-input style="width: 95%" v-model="downloadForm.serialNumber" placeholder="Please enter the purchased License serial number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span class="dialog-footer">
        <el-button type="primary" @click="downloadSure">confirm</el-button>
        <el-button @click="downloadDialog=false">cancel</el-button>
      </span>
      <div class="warm-tips">
        <div>
          <img :src="$http + '/zh/orderOnline/tipsIcon.png'" alt="">
          <span>Tips: If you have not purchased the product, please complete the purchase first!</span>
        </div>
        <el-button class="buy-btn" @click="toBuy">Go and buy</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="tipsDialog"
      width="32%"
      class="download-check"
      :class="downloadNum<allowDownloadNum?'download-success':'download-fail'"
      :before-close="handleClose"
      :close-on-press-escape='false'
      :close-on-click-modal='false'>
      <div slot="title">
        <i class="el-icon-circle-check" v-if="downloadNum<allowDownloadNum"></i>
        <span v-if="downloadNum<allowDownloadNum">Sn The License is successfully verified</span>
        <i class="el-icon-warning-outline" v-if="downloadNum>=allowDownloadNum"></i>
        <span  v-if="downloadNum>=allowDownloadNum">Attention please</span>
      </div>
      <div class="download-box">
        <div class="box-header">Dear users:</div>
        <div class="default-tips">
          <div>Hello！</div>
          <div v-if="downloadNum == 0">Your serial number License {{downloadForm.serialNumber}} Successful verification</div>
          <div v-if="downloadNum == 0">Please click to download</div>
          <div v-if="downloadNum > 0 && downloadNum<allowDownloadNum">Your serial number License {{downloadForm.serialNumber}} Successful verification，Downloaded times {{downloadNum}}</div>
          <div v-if="downloadNum > 0 && downloadNum<allowDownloadNum">Please click to download</div>
          <div v-if="downloadNum > 0 && downloadNum>=allowDownloadNum">Your serial number License {{downloadForm.serialNumber}}，Downloaded times {{downloadNum}}</div>
          <div v-if="downloadNum > 0 && downloadNum>=allowDownloadNum">If you still need to download, please contact customer service to confirm the transaction information and download again.</div>
          <div v-if="downloadNum > 0 && downloadNum>=allowDownloadNum">Customer service email：service@longsqin.com</div>
          <el-button :loading='loading' class="down-btn" v-if="allowDownloadNum>0 && allowDownloadNum>downloadNum" @click="downloadFileBtn">
            <div v-if="!loading">
              <img :src="$http + '/zh/download/downloadIcon.png'" alt="">
              <span>Download</span>
            </div>
            <div v-else>
              {{percentComplete.toFixed(2)}}%
            </div>
          </el-button>
        </div>
      </div>
    </el-dialog>
    <common-footer ref="commonFooter"></common-footer>
    <order-online v-if="orderDialog" ref="orderDialog" @closeOnline='closeOnline'></order-online>
  </div>
</template>

<script>
import commonFooter from "../../components/en/commonFooter.vue";
import commonHeader from "../../components/en/commonHeader.vue";
// 在线订单组件
import orderOnline from '../../components/en/orderOnline.vue'
import { downloadDetail, serialNumberDownload } from '@/api/download'

export default {
  components: {
    commonHeader,
    commonFooter,
    orderOnline
  },
  data() {
    return {
      orderDialog: false,//在线订单
      // 产品列表
      tableData: [
        {
          products: "Qin Strings",
          type: "Strings",
          fileSize: "554MB",
          updateTime: "2024.07.31",
        },
      ],
      downloadDialog: false, // 下载弹窗
      // 下载表单
      downloadForm: {
        serialNumber: ''
      },
      downloadRules: {
        serialNumber: [{required: true, message: 'Please enter serial number', trigger: ['change', 'blur']}]
      },
      allowDownloadNum: 0, // 允许下载次数
      downloadNum: 0, // 下载次数
      tipsDialog: false, // 提示弹窗
      realName: null, // 真实文件名称
      loading: false, // 是否正在下载
      percentage: 0, // 下载进度
      fileName: '', // 文件名称
      totalBytes: 0, // 文件总量
      receivedBytes: 0, // 当前下载量
      percentComplete: 0, // 当前下载进度
      timer: null, // 定时器
    };
  },
  mounted() {
    if(this.$route.query.tradeNo){
      this.orderDialog = true
      // this.stopMove()
    }
    if(this.$route.query.key){
      this.getDownloadNum(this.$route.query.key, true)
      this.downloadForm.serialNumber = this.$route.query.key
    }
  },
  methods: {
    // el-table自定义样式
    changeCellStyle(row){
      if(row.column.label === 'Product information'){
        return "padding-left:33px;height:90px !important"
      }else{
        return "height:90px !important"
      }
    },
    // dialog不让点击外面关闭s
    handleClose(done){
      done()
    },
    // 分词下载方法
    downloadConcurrentSegments() {
      let that = this
      that.loading = true
      // let limit = 1024 // 每次获取多少
      // let maxSize = 6480 // 总共的文件大小
      // let limit = 1073741824 // 每次获取多少
      // let maxSize = 4644503552 // 总共的文件大小
      let limit = 24806183 // 每次获取多少
      let maxSize = 545736026 // 总共的文件大小
      const totalSegments = Math.ceil(maxSize/limit)-1; // 总共分为几段下载
      const downloadPromises = []; // promise下载集

      for (let i = 0; i <= totalSegments; i++) {
          let byteStart = 0
          let byteEnd = 0
          if(i==0){
            byteStart = i * limit
            byteEnd = (i+1) * limit
          }else if(i>0 && i<totalSegments){
            byteStart = (i * limit)+1
            byteEnd = (i+1) * limit
          }else if(i == totalSegments){
            byteStart = (i * limit)+1
            byteEnd = maxSize-1
          }
          let bytes = 'bytes=' + byteStart + '-' +  byteEnd
          downloadPromises.push(this.downloadSegment(bytes)); // 段号从1开始
      }
      that.percentComplete = 0
      clearInterval(that.timer)
      // 虚假的下载进度。每次更新需要重新计算
      that.timer = setInterval(() => {
        that.percentComplete = that.percentComplete + maxSize/1024/1024/7/100*0.3  // 文件尺寸变更为mb，按每秒7mb跑
        if(that.percentComplete>=99){ // 如果进度超过100，下载还没完成，则一直在99.99循环
          that.percentComplete = 99.99
        }
      }, 300);
      Promise.all(downloadPromises).then(responses => {
        const mergedArrayBuffer = responses;
        const blob = new Blob(mergedArrayBuffer);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = that.fileName;
        link.click();
        that.loading = false
        // 下载完成的请求
        serialNumberDownload({
          serialNumber: that.downloadForm.serialNumber
        }).then(res=>{
          that.percentComplete = 100
          clearInterval(that.timer)
          that.$message.success('Download successfully')
          that.loading = false
          that.getDownloadNum(that.downloadForm.serialNumber)
        })
      }).catch(error => {
        console.error('Error downloading segments:', error);
      });
    },
    // 实际下载方法
    downloadSegment(segment) {
      const url = '/qy/base-file/rangeDownloadByProductId?serialNumber='+this.downloadForm.serialNumber
      return fetch(url, {
        headers: {
          range: segment
        }
      }).then(response => {
          let that = this
          const fileNameEncode = response.headers.get('Content-Disposition') ? response.headers.get('Content-Disposition').split(';')[1].split('=')[1] : ''
          that.fileName = fileNameEncode
          let contentLength = parseInt(response.headers.get('Content-Length'), 10);
          that.totalBytes += contentLength || 0;
          let cloneResult = response.clone()
          const reader = cloneResult.body.getReader();
          reader.read().then(({ done }) => {
            if (done) {
              // onComplete();
              return;
            }
            return read();
          });
          function read() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                  // onComplete();
                  return;
              }
              that.receivedBytes += value.byteLength;
              // that.percentComplete = (that.receivedBytes / that.totalBytes) * 100;
              return read();
            });
          }
          return response.arrayBuffer();
      });
    },

    // 下载方法
    downloadFunc(){
      // 如果正在下载中。则出现下载中的的弹窗
      if(this.loading){
        this.getDownloadNum(this.downloadForm.serialNumber, true)
      }else{
        this.downloadDialog = true
        this.downloadForm.serialNumber = ''
        this.receivedBytes = 0
        this.totalBytes = 0
        this.percentComplete = 0
        clearInterval(this.timer)
        this.$nextTick(()=>{
          this.$refs.downloadForm.clearValidate()
        })
      }
    },
    // 获取下载次数
    downloadSure(){
      this.$refs.downloadForm.validate((valid) => {
        if (valid) {
          this.getDownloadNum(this.downloadForm.serialNumber, true)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取下载次数
    getDownloadNum(serialNumber, showDialog){
      downloadDetail({
        serialNumber
      }).then(res=>{
        if(!res.data){
          this.$message.error('No serial number is found. Please check the serial number and search again')
          return
        }
        this.allowDownloadNum = res.data.allowDownloadNum||0
        this.downloadNum = res.data.downloadNum||0
        if(showDialog){
          this.downloadDialog = false
          this.tipsDialog = true
        }
      })
    },  
    // 去购买
    toBuy(){
      this.orderDialog = true
      this.downloadDialog = false
      // this.stopMove()
    },
    // 关闭在线订单
    closeOnline(){
      this.orderDialog = false
      // this.Move()
      if(this.$route.query.tradeNo){
        this.$router.push({
          path: this.$route.path
        })
      }
    },
    //停止页面滚动
    stopMove(){
      let m = function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    //开启页面滚动
    Move(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    },
    downloadFileBtn(){
      // let url = '/qy/base-file/downloadByProductId?serialNumber='+this.downloadForm.serialNumber
      // this.downloadFile(url)
      this.downloadConcurrentSegments()
    },
    async downloadFile(fileUrl) {
      let blob = await this.getBlob(fileUrl);
      this.saveFile(blob, this.realName);
    },
    getBlob(fileUrl) {
      let that = this
      that.loading = true
      that.percentage = 0
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl, true);
        //监听进度事件
        console.log(xhr);
        xhr.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total;
              // percentage是当前下载进度，可根据自己的需求自行处理
              let percentage = percentComplete * 100;
              that.percentage = percentage
            }
          },
          false
        );
        xhr.addEventListener('abort', function(){
          // 下载服务异常，请联系管理员
          that.$message.warning('The download service is abnormal. Contact the administrator！')
          that.loading = false
        })
        xhr.responseType = 'blob';
        xhr.onload = () => {
          // 下载完成
          if (xhr.status === 200) {
            //请求头信息
            var myHeader = xhr.getResponseHeader('Content-Disposition');
            if(!myHeader){
              that.loading = false
              that.percentage = 0
              // 下载服务异常，请联系管理员
              this.$message.error('The download service is abnormal. Contact the administrator')
              reject('false')
            }
            // 文件名称
            this.realName = myHeader.split(';')[1].split('=')[1]
            serialNumberDownload({
              serialNumber: that.downloadForm.serialNumber
            }).then(res=>{
              // 下载成功
              that.$message.success('Download successfully')
              that.getDownloadNum(this.downloadForm.serialNumber)
              that.loading = false
              resolve(xhr.response);
            })
          }
        };
        xhr.send();
      });
    },
    saveFile(blob, fileName) {
      // ie的下载
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        // 非ie的下载
        const link = document.createElement('a');
        const body = document.querySelector('body');

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);

        link.click();
        body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.download-page {
  width: 100%;
  height: 100%;
  .download-content {
    position: relative;
    width: 100%;
    height: 100%;
    .download-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background: #000;
    }
    .download-body {
      padding: 115px 252px 278px 252px;
      box-sizing: border-box;
      .download-title-img{
        width: 596px;
        height: 122px;
      }
      .download-table {
        margin-top: 34px;
        width: 100%;
        min-height: 280px;
        .el-table {
          font-size: 22px;
          color: #000;
          font-family: PingFang SC;
          ::v-deep tr{
            background: #fafafa;
          }
          ::v-deep th{
            background: #fafafa;
          }
          ::v-deep .el-table__row td {
            border-bottom-color: #fff !important;
          }
          ::v-deep .cell{
            height: 90px;
            line-height: 90px;
          }
          img{
            width: 28px;
            height: 28px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialog-footer{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .warm-tips{
    margin: 60px 20px 0 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    justify-content: space-between;
    padding: 18px 0px 0 0px;
    div{
      display: flex;
      align-items: center;
      img{
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
    .buy-btn{
      color: #4aa4ea;
      border-color: #4aa4ea;
      padding: 12px 40px;
    }
  }
  .download-fail{
    ::v-deep .el-dialog{
      background: #fdf9ed;
    }
  }
  .download-success{
    ::v-deep .el-dialog{
      background: #eff8e9;
    }
  }
  .download-check{
    ::v-deep .el-dialog{
      .el-icon-circle-check{
        color: #97cd74;
        margin-right: 16px;
      }
      .el-icon-warning-outline{
        color: #f3c665;
        margin-right: 16px;
      }
      .el-dialog__body{
        padding: 0 0 12px 0;
      }
      .download-box{
        background: #fff;
        margin: 0px 12px 12px 48px;
        box-sizing: border-box;
        padding: 16px 24px 24px 24px;
        .default-tips{
          div{
            margin: 4px 0;
            margin-left: 28px;
          }
          .down-btn{
            margin-left: 28px;
            margin-top: 12px;
            width: 160px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #9cc463;
            border-radius: 4px;
            cursor: pointer;
            div{
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: 0;
              margin: 0;
              img{
                margin-right: 12px;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
}
</style>